import React, { useState, useContext, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { useMount } from 'react-use'

import { Layout } from '../components'
import { media } from '../styles/utils'
import { container, padding, bgImage } from '../styles/global'
import { parseACF, getGlobalData } from '../utils'
import { BGVideoContext } from '../context/BGVideoContext'

const Index = props => {
    const staticQuery = useStaticQuery(query)
    const data = props.previewData || parseACF(staticQuery, 'allWordpressPages')
    const { words: wordsData } = getGlobalData()
    const globalData = getGlobalData()
    const videoContext = useContext(BGVideoContext)
    const [hovering, setHovering] = useState(false)

    useMount(() => {
        videoContext.setDefault()
    })

    const renderWords = () => {
        const items = wordsData.map((item, i) => {
            return (
                <Item
                    key={i}
                    as={Link}
                    to={`/${item.slug}`}
                    style={{opacity: hovering === item.slug ? 1 : 0.3}}
					onMouseEnter={() => {
                        setHovering(item.slug)
                        videoContext.setActiveVideo(item.background_video)
                    }}
					onMouseLeave={() => {
                        setHovering(false)
                        videoContext.setDefault()
                    }}
                >
                    {item.title}
                </Item>
            )
        })
        return <Words>{items}</Words>
    }

    return (
        <Layout meta={data && data.seo}>
            <Wrapper>
                <Grid>
                    <Heading
                        style={{opacity: hovering ? 0.3 : 1}}
                    >
                        Digæst
                    </Heading>

                    {renderWords()}
                    <Description
                        style={{opacity: hovering ? 0.3 : 1}}
                        dangerouslySetInnerHTML={{
                            __html: data?.description,
                        }}
                    />
                </Grid>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Wrapper = styled.div`
    ${tw`w-full h-full mx-auto`}
    max-width: 1366px;
`

const Heading = styled.h1``

const Item = styled.div``
const Words = styled.div`
    ${tw`flex flex-col items-start`};

    ${media.mob`
        ${tw`order-last items-center`};
    `}

    ${Item} {
        ${tw`my-4`};
        transition: opacity 0.45s ease;
        transform: translateY(100%);

        ${media.mob`
            ${tw`my-2`};
            transform: none;
            opacity: 1!important;
        `}
    }
`

const Description = styled.div``

const Grid = styled.div`
    ${tw`flex items-center w-full h-full`}; 

    ${media.mob`
        ${tw`flex-col justify-between py-12`};
    `}

    ${Heading}, ${Words} {
        &, * {
            font-size: 35.5px;
            line-height: 1.28;

            ${media.mob`
                font-size: 24px;
            `}
        }
    }

    ${Heading} {
        width: 25%;
        transition: opacity 0.45s ease;

        ${media.mob`
            ${tw`text-center`};
        `}
    }

    ${Words} {
        width: 25%;
    }

    ${Description} {
        width: 50%;
        transition: opacity 0.45s ease;
    }

    ${media.mob`
        ${Heading}, ${Words}, ${Description} {
            ${tw`w-full`};
        }
    `}
`

export const query = graphql`
    query {
        allWordpressPages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Index
